﻿@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Bold.eot");
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */
  url("../fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
  url("../fonts/woff/IRANSansWeb(FaNum)_Bold.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url("../fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Medium.eot");
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */
  url("../fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
  url("../fonts/woff/IRANSansWeb(FaNum)_Medium.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url("../fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Light.eot");
  src: url("../fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */
  url("../fonts/woff2/IRANSansWeb(FaNum)_Light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
  url("../fonts/woff/IRANSansWeb(FaNum)_Light.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url("../fonts/ttf/IRANSansWeb(FaNum)_Light.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot");
  src: url("../fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */
  url("../fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
  url("../fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url("../fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/eot/IRANSansWeb(FaNum).eot");
  src: url("../fonts/eot/IRANSansWeb(FaNum).eot?#iefix") format("embedded-opentype"),
    /* IE6-8 */
  url("../fonts/woff2/IRANSansWeb(FaNum).woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
  url("../fonts/woff/IRANSansWeb(FaNum).woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url("../fonts/ttf/IRANSansWeb(FaNum).ttf") format("truetype");
}
