.login-wrapper {
  direction: rtl;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-layout {
    min-width: 65vw;
    box-shadow: 0 2px 161px 0 rgba(0, 0, 0, 0.19);
    border-radius: 60px;
    margin-bottom: 2em;
  }

  &-image-responsive {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 700px;
  }
}

.account {
  flex-grow: 1;
}

.welcome-wrapper{
  min-height: 257px;
  max-height: 60vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.general-wrapper {
  margin: 30px 0 20px 0;
  width: 77%;

  &.cc {
    width: 81%;
  }
}

.phoneNumber-form {
  text-align: right;
  padding-bottom: 65px;
  border-bottom: 1px solid #979797;
}

.phoneNumber-wrapper {
  flex-grow: 0.5;

  &-footer {
    margin-top: 45px;

    &-text {
      color: #03447b;
      cursor: pointer;
      font-size: 1em;
      font-weight: 700;
      margin-top: 20px !important;
      margin-bottom: 35px;
    }
  }
}

button {
  font-size: 1.2rem;
}


.notify-warning {
  display: flex;
  margin-top: 45px !important;;
  padding: 40px !important;;
  max-width: 410px !important;;
  text-align: center;
  & > div {
    margin: 0;
  }
  &-btn-wrap {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
}
.login-button {
  border-radius: 50px;
  padding: 17px;
  box-shadow: 0 2px 19px 0 rgba(2, 87, 145, 0.51) !important;
  color: #ffffff;
  margin-top: 10px !important;

  &:focus {
    outline: none;
  }

  // trick to transition on gradients
  position: relative;
  background-image: linear-gradient(to bottom, #056ba7, #02396e);
  z-index: 1;
}

.light-button {
  @extend .login-button;
  background-image: linear-gradient(to bottom, #056ba700, #02396e00);
  color: #02396e;
  box-shadow: 0 2px 19px 0 rgba(2, 87, 145, 0.32) !important;
  transition: all .3s linear;
  &:hover {
    opacity: 0.8;
    background-image: linear-gradient(to bottom, #02396e00, #056ba700);
  }
  &:hover::before {
    opacity: 0 !important;
  }
}

.login-button-disabled {
  opacity: 0.7;
}

.login-button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #02396e, #056ba7);
  z-index: -1;
  transition: opacity 0.25s linear;
  opacity: 0;
}

.login-button:hover::before {
  opacity: 1;
}

.login-button.login-button-disabled:hover::before {
  opacity: 0;
}

.login-div {
  border-radius: 58px;
  box-shadow: 0 2px 161px 0 #d4cfcf;
  border: solid 3px #ffffff;
  padding: 0 18px;
}

.back-icon {
  text-align: right;
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.error-text {
}

.cc {
  //flex-grow: 0.5;

  &--form {
    padding-bottom: 65px;
    border-bottom: 1px solid #979797;
  }

  &--phone_number {
    font-size: 15px;
    margin-top: 1rem;
    color: #888888;
  }


  &--input {
    box-shadow: 0 2px 161px 0 #d4cfcf;
    text-align: center !important;
    letter-spacing: 5px;

    &::placeholder {
      text-align: center !important;
      letter-spacing: 10px !important;
      font-size: 20px !important;
    }
  }

  &--label {
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0 !important;
  }

  &--back {
    font-size: 15px;
  }

  &--timer-wrapper {
    margin-top: 3rem;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    color: #03447b;
  }

  &--timer-text {
    color: #888888;
    font-size: 14px;
    margin-left: 0.75rem;
    margin-right: 0.8rem;
  }

  &--timer-number {
    font-family: monospace;
  }
}

.confirm-p {
  font-size: 12px;
}

.resend-code {
  cursor: pointer;
  color: #03447b;

  &--password {
    font-size: 10px;
    margin-left: 15px;
  }
}

.userName-login {
  padding-bottom: 65px;
  border-bottom: 1px solid #979797;

  &-col {
    flex-grow: 0.3;
    margin: 60px 0 20px 0;
  }

  &-label {
    font-size: 20px !important;
    justify-content: space-between;

    & span {
      font-size: 15px;
    }
  }

  &-password {
    position: relative;
  }

  &-password-icon {
    position: absolute;
    top: 30%;
    cursor: pointer;
    right: 62px;
    transform: translateY(-30%);
  }
}

.create-account-login-password-icon {
  position: absolute;
  top: 30%;
  cursor: pointer;
  right: 25%;
  transform: translateY(-30%);
}


.field {
  &--icon_box {
    background-color: #d5eaed !important;
    border-radius: 65% !important;
    width: 50px;
    padding: 12px;
    height: 50px;
    margin-right: 7px;
    display: flex;
    justify-content: center;
  }

  &--label {
    font-size: 1.5em !important;
    margin: 3em 0 3em;
    display: block;
  }

  &--icon {
    height: 25px;
    display: inline-block;
    margin: auto;
  }

  &--button {
    border-radius: 50px;
    padding: 17px;
    background-image: linear-gradient(to bottom, #056ba7, #02396e);
    box-shadow: 0 2px 19px 0 #056ba7;
    color: #ffffff;
    font-size: 20px;

    &:focus {
      outline: none !important;
      box-shadow: 0 2px 19px 0 #056ba7;
    }
  }

  &--input {
    border: none;
    border-radius: 50px;
    padding: 12px 30px;
    height: unset !important;

    &:focus {
      border: none;
    }
  }

  &--footer {
    margin-top: 45px;
  }

  &--text {
    color: #03447b;
    font-weight: 700;
    cursor: pointer;
  }

  &-error {
    min-height: 20px;
    width: 100%;
  }
}

.phone-number {
  &--input {
    direction: ltr;
    letter-spacing: 5px;
    text-align: left;

    &::placeholder {
      letter-spacing: 5px !important;
      text-align: left;
    }
  }
}

.password-input {
  letter-spacing: 5px;
}

.accounts {

  &--pass_container {
    position: relative;
  }

  &--pass_input {
    text-align: left;
    letter-spacing: 5px;

    &::placeholder {
      text-align: center;
    }
  }

  &--username_input {
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
}

.new-account {
  &--back {
    cursor: pointer;
  }
}

.tab-content {
  box-shadow: none;
  padding: none;
  border: none;
}

.exist-tab {
  .userName-login {
    border: none;
  }

  .userName-login-col {
    margin-top: 30px;
  }
}

.account-list--wrapper {
  border-bottom: 1px solid #979797;
  padding-bottom: 45px;
}

.account {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 50px 70px;

  h6 {
    font-size: 1.45em !important;
    font-weight: 700;
    line-height: 2rem;
  }

  &-list {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 999px;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: 0 2px 161px 0 rgba(0, 0, 0, 0.15);
    height: 65px;
    padding: 35px 40px;
    border: 1px solid #03447B;
    &.loading {
      cursor: default;
    }
    span,
    svg.fa-user {
      color: rgb(112, 112, 112);
      transition: all 0.3s ease-in-out;
    }

    .username {
      color: #70707085;
      font-size: 0.8rem;
    }

    &:hover {
      background: rgba(233, 241, 250, 0.3);

      span,
      svg.fa-user {
        color: #000;
      }
    }

    &-icon {
      font-size: 15px;

      &-chevron {
        color: #0e69a1;
      }
    }
  }

  &-submit {
    background-image: linear-gradient(to bottom, #056ba7, #02396e);
    box-shadow: 0 2px 19px 0 var(--peacock-blue-51);
    border-radius: 999px;
    border: 0;
    padding: 15px;
  }

  &-control {
    font-size: 10px;
    border: 0 !important;
    border-radius: 999px !important;
    padding: 25px !important;
    background: #fff !important;
    box-shadow: 0 52px 134px 0 #575757 !important;
  }

  &-line {
    border: 1px solid #e7e7e7;
  }

  &-error {
    font-size: 10px;
  }

  &-no-user {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.label-wrapper {
  position: relative;
  width: 100%;
}


.form-group-custom {
  position: relative;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.14);
  border-radius: 50px !important;
  padding: 5px 4px;

  .field--input {
    padding-top: 1.05rem;
    padding-bottom: 0.55rem;
    border: none;
    border-radius: 50px;
  }

  .form-label {
    right: 20px;
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    transition: 0.5s;
    display: flex;
    align-items: center;

    span.label-content {
      transition: 0.3s;
      opacity: 0.5;
      display: inline-block;
      background-color: rgba($color: #fff, $alpha: 0);
      padding: 1px 5px;
      border-radius: 5px;
      border: 1px solid transparent;
      color: #056ba7ad;
    }
  }

  textarea.form-control ~ .form-label {
    align-items: flex-start;
    top: 10%;
  }


  .form-control:focus, .form-control:not(:placeholder-shown) {
    & + .form-label {
      span.label-content {
        transform: translateY(-65%);
        opacity: 1;
        font-size: 12px;

        span {
          color: red;
          font-size: 14px;
        }
      }
    }
  }

  .form-control::placeholder {
    opacity: 0;
  }

  .form-control {
    background-color: transparent;
    box-shadow: unset !important;
  }
}

.text-danger {
  text-align: right;
}

input[name="password"], input[name="identifier"] {
  direction: ltr;
}



//------------------- RESPONSIVE ----------------------//
@media (max-width: 992px) {
  .login-wrapper {
    &-layout {
      //box-shadow: none;
      width: 90%;
      min-width: 500px;
    }

    &-image {
      display: none;
    }
  }

  .field {
    &--label {
      text-align: center;
      font-size: 1em !important;
    }
  }
  .back-icon {
    font-size: 0.7em;
  }

  .account {
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  &-image {
    display: none;
  }
  .login-wrapper {
    &-layout {
      width: 90%;
      min-width: 400px;

      .container {
        padding: 0 2rem !important;
      }
    }
  }
  .cc {
    &--timer {
      text-align: center;
    }
  }
  .create-account-login-password-icon {
    right: 35%;
  }
}

@media (max-width: 567px) {
  .account {
    padding: 0 !important;
  }
  .account h6 {
    font-size: 1.3rem !important;
  }
  .login-wrapper {
    &-layout {
      width: 94% !important;
      min-width: 300px !important;

      .container {
        padding: 0 1rem !important;
      }
    }

  }
  .phoneNumber-form {
    input {
      font-size: 0.8em;
    }
  }
  .heading-title {
    font-size: 1.3rem !important;
  }
  .general-wrapper {
    width: 90%;
  }
  .cc {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--timer {
      font-size: 0.7rem;
    }

    &--timer-text {
      font-size: 0.7rem;
    }
  }
}
