/* IMPOTRING VARIABLES */
@import "src/framework/assets/styles/fontiran";
@import "bootstrap/scss/bootstrap";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: IRANSans;
}

body {
  background: rgba(255, 255, 255, 0.98);
}

a {
  transition: all 0.3s ease;

  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}

.app {
  min-height: 100vh;

  .empty-state {
    color: white;
    padding: 30px 15px;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    margin-top: 50px;
  }
}

.logo {
  margin: 2em auto 2em;
  width: 100px;

  &.logo-sm {
    width: 85px;
  }

  &.logo-lg {
    width: 150px;
  }

  .logo--img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.w-95 {
  width: 95% !important;
}

.custom-notify {
  line-height: 1.9rem;
  font-size: 1.15rem;
  padding: 10px !important;
  left: 30px;
  position: relative;
  text-align: right;
}

.btn-outline-success {
  border-radius: 50px !important;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 13px 27px;
}

.green {
  .btn-outline-success {
    border: solid 1px hsl(172, 75%, 43%);
    color: hsl(172, 75%, 43%);

    &:hover {
      background-color: hsl(172, 75%, 43%);
      border: solid 1px hsl(172, 75%, 43%);
      color: #fff;
    }
  }


  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: hsl(172, 75%, 35%);
    border-color: hsl(172, 75%, 35%);
    box-shadow: none;
  }

  .btn-success:hover, .btn-success:focus {
    background-color: hsl(171.9, 75.1%, 96.5%);
    border: 1px solid hsl(172, 75%, 43%);
    box-shadow: none !important;

  }

  .btn-success.header--dropdown {
    background-color: hsl(172, 75.1%, 42.5%);
    border: none;

    &:active {
      background-color: hsl(171.9, 75.1%, 35%) !important;
      color: white !important;
    }

    &:hover {
      background-color: hsl(171.9, 75.1%, 35%) !important
    }
  }

  .header--dropdown.btn-success:not(:disabled):not(.disabled):active, .header--dropdown.btn-success:not(:disabled):not(.disabled).active, .show > .header--dropdown.btn-success.dropdown-toggle {
    background-color: hsl(171.9, 75.1%, 42.5%);
    color: white !important;
  }

  .dropdown .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: hsl(172, 75%, 45%);
    background-color: hsl(171.9, 75.1%, 96.5%);
    border-color: hsl(172, 75%, 35%);
    box-shadow: none;
  }


  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    background-color: hsl(172, 75%, 43%);
    border-color: hsl(172, 75%, 43%);
    box-shadow: none;
  }

}


.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: none;
}

.bg-red {
  background: white;
}

.create-user-notify-warning{
  span {
    font-size: 1.2rem;
    text-align: right !important;
  }
}

.text-black {
  color: black !important;
}

@media (max-width: 534px) {
  .custom-notify {
    left: 10px;
    position: relative;
  }
  h4 {
    font-size: 1.3rem !important;
  }
  .cc--phone_number, span {
    font-size: 0.85rem !important;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .btn-outline-success {
    padding: 15px 23px;
  }
}

@media (max-width: 992px) {
  .logo {
    margin-bottom: 1em;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1300px;
  }
}
