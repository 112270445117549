.profile-form--wrapper {
  flex: 1;
  margin: 0 0 140px;

  &--label {
    font-size: 12px !important;
    margin: 1em 0 !important;
  }

  &--wrapper {
    height: 50px !important;
    padding: 0px 10px 5px 18px !important;
  }
}

.profile {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(121, 120, 120, 0.7);
}

.success-message {
  text-align: right;
  color: green;
}

.field-error {
  text-align: right;
  position: relative;
  right: 10px;
}

.none-field-errors {
  color: red;
  direction: rtl;
  text-align: right;
}

@media (max-width: 375px){
  .field-error {
    width: 230px;
  }
}